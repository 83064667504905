import React from 'react'
import { Link } from "gatsby"
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Section from './section'
import TagsSection from './section-tags'
import Content from './html-content'
import Seo from './seo'

const backButton = (
  <Button component={Link} to={`/blog`} startIcon={<ArrowBackIcon />}>
    Back to Blog
  </Button>
)
export default ({ post }) => (
  <>
    <Seo
      title={post.frontmatter.title}
      description={post.frontmatter.description}
      article
      image={post.frontmatter.teaserImage.childImageSharp.fluid.src}
    />
    <TagsSection backButton={backButton} tags={post.frontmatter.tags} link={true} />
    <Section>
      <Grid container spacing={3}>
        <Grid item xs={12} md={9}>
          <Content html={post.html} />
        </Grid>
        <Grid item xs={12} md={3}>
          <Content html={post.tableOfContents} />
        </Grid>
      </Grid>
    </Section>
  </>
)
