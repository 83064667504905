import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import HeaderSection from '../components/section-header'
import BlogPostSection from '../components/section-blog-post'


export default ({ data }) => {
  const post = data.blogPost

  return (
    <Layout>
      <HeaderSection
        small
        eyebrow={post.frontmatter.date}
        title={post.frontmatter.title}
      />
      <BlogPostSection post={post} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostQuery($id: String!) {
    blogPost: markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        description
        date(formatString: "MMMM DD, YYYY")
        teaserImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        tags
      }
      html
      tableOfContents
    }
  }
`